<template>
  <div>
    <Toolbar :handle-edit="() => editHandler(item)">
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <br/>
    <div v-if="item">
      <v-card class="mt-4 mx-auto" max-width="860px">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>{{ $t('Profile') }}</v-toolbar-title>
        </v-toolbar>
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            {{ $t('Profile') }}
            <v-icon>mdi-account</v-icon>
          </v-tab>
          <v-tab>
            {{ $t('Addresses') }}
            <v-icon>mdi-card-account-details</v-icon>
          </v-tab>
          <v-tab>
            {{ $t('Contacts') }}
            <v-icon>mdi-phone</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="mt-5" flat>
              <v-card-text class="text-center">
                <h1 class="font-weight-light mb-3 black--text">
                  {{ item.salutation }} {{ item.title }} {{ item.firstName }}
                  {{ item.lastName }}
                </h1>
                <h5 v-if="item.company" class="mb-1 grey--text">{{ item.company }}</h5>
                <v-avatar v-if="document" size="90">
                  <v-img :src="documentUrl"></v-img>
                </v-avatar>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mt-5" flat>
              <v-card-text>
                <template v-if="addressesLoaded">
                  <AddressList
                    :addressObjects="this.addressObjects"
                  />
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
                <v-btn color="primary" dark @click="addressDialogControl()">
                  {{ $t('AddAddress') }}
                </v-btn>
                <AddressDialog
                  v-model="showAddressDialogForm"
                  :address-item="{ country: 'DE'}"
                  :handle-created="onAddressCreated"
                  :handle-deleted="onAddressDeleted"
                  :show-handle="showAddressDialogForm"
                  :title="$t('AddAddress')"
                  @close="addressDialogControl(true)"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mt-5" flat>
              <v-card-text>
                <ContactList
                  :contact-objects="contactsObjects"
                  :contacts-server-length="item.contacts.length"
                  :handle-created="onContactCreated"
                  :handle-deleted="onContactDeleted"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import DocumentMixin from '../../mixins/DocumentMixin';
import Toolbar from '../../components/Toolbar';
import AddressDialog from '../../components/address/AddressDialog';
import AddressList from '../../components/address/AddressList';
import ContactList from "@/components/contact/ContactList";
import ContactMixin from "@/mixins/ContactMixin";
import ProfileMixin from "@/mixins/ProfileMixin";
import AddressMixin from "@/mixins/AddressMixin";

const servicePrefix = 'Profile';

export default {
  name: 'ProfileShow',
  servicePrefix,
  components: {
    Loading,
    Toolbar,
    AddressDialog,
    AddressList,
    ContactList
  },
  mixins: [ShowMixin, DocumentMixin, ProfileMixin, AddressMixin, ContactMixin],
  data() {
    return {
      tab: true,
      isLoadingAddresses: false,
      loadedAddressItems: 0,
      showAddressDialogForm: [],
    }
  },
  computed: {
    ...mapFields('profile', {
      isLoading: 'isLoading',
    }),
    ...mapGetters('profile', ['find']),
    addressesLoaded() {
      return this.item.addresses.length === this.addressObjects.length;
    },
    addressObjects() {
      return this.item.addresses.map((addressId) => this.resolveAddress(addressId));
    },
    contactsObjects() {
      return this.item.contacts.map((contactId) => this.findContact(contactId));
    },
  },
  methods: {
    ...mapActions('profile', {
      editItem: 'edit',
      reset: 'resetData',
      retrieve: 'load',
      updateProfile: 'update',
    }),
    onAddressCreated(createdAddress) {
      this.item.addresses.push(createdAddress['@id']);
      let fakeItem = {'@id': this.item['@id'], 'addresses': this.item.addresses};
      this.updateProfile(fakeItem);
    },
    onContactCreated(createdContact) {
      this.item.contacts.push(createdContact['@id']);
      let fakeItem = {'@id': this.item['@id'], 'contacts': this.item.contacts};
      this.updateProfile(fakeItem);
    },
    addressDialogControl(close = false) {
      if (!close) {
        this.showAddressDialogForm.push("new");
      } else {
        this.showAddressDialogForm.splice(this.showAddressDialogForm.indexOf("new"), 1);
      }
    },
    onContactDeleted(deletedContact) {
      this.removeProfileRelated(this.item, 'contacts', deletedContact['@id']);
    },
    onAddressDeleted(deletedAddress) {
      // TODO: Call early, prevent not found
      this.removeProfileRelated(this.item, 'addresses', deletedAddress['@id']);
    },
  },
  watch: {
    item() {
      if (!this.item) {
        return;
      }
      if (this.item.image) {
        this.retrieveDocument(this.item.image);
      }
    },
  }
};
</script>
